import { usePostTrackEventWithParams } from "./analyticsQueries"

export type THomegroupEventTypes =
  | "Change Location Address Clicked"
  | "Add Location Address Clicked"
  | "Add Location Address Initiated"
  | "Add Location Address Confirmed"

function usePostHomegroupTrackingEvent<TEventProperties>(
  event: THomegroupEventTypes
) {
  return usePostTrackEventWithParams<THomegroupEventTypes, TEventProperties>({
    event,
  })
}

export function usePostHomegroupChangeAddressClicked() {
  const x = usePostHomegroupTrackingEvent("Change Location Address Clicked")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressClicked() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Clicked")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressInitiated() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressConfirmed() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Confirmed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}
